const menuList = [
    {
        label: 'default',
        items: [
            {
                label: 'dashboard',
                icon: 'ri-dashboard-line ri-lg',
                to: '/',
                action: 'view',
                resource: 'dashboard'
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'Administration',
                icon: 'ri-book-line ri-lg',
                action: 'view',
                resource: 'users',
                items: [
                    {
                        label: 'user.menu',
                        icon: 'ri-user-3-line ri-lg',
                        action: 'view',
                        resource: 'users',
                        to: '/user'
                    },
                    {
                        label: 'Referrals',
                        icon: 'ri-group-line ri-lg',
                        action: 'view',
                        resource: 'referrals',
                        to: '/referrals'
                    },
                    {
                        label: 'Family Member',
                        icon: 'ri-group-line ri-lg',
                        action: 'view',
                        resource: 'familymembers',
                        to: '/family-members'
                    },
                    {
                        label: 'Registration',
                        icon: 'ri-user-shared-line ri-lg',
                        action: 'view',
                        resource: 'registration-requests',
                        to: '/registration-request'
                    },
                    {
                        label: 'Employee Records',
                        icon: 'ri-team-line ri-lg',
                        action: 'view',
                        resource: 'employee_records',
                        to: '/employee-record'
                    },
                    {
                        label: 'Audit Log',
                        icon: 'ri-file-list-3-line ri-lg',
                        action: 'view',
                        resource: 'audit-logs',
                        to: '/audit-log'
                    }
                ]
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'Appointment Slot',
                icon: 'ri-coupon-line ri-lg',
                action: 'view',
                resource: 'doctor-attendances',
                items: [
                    {
                        label: 'timeslot.timeslot_menu',
                        icon: 'ri-timer-line ri-lg',
                        action: 'view',
                        resource: 'doctor-timeslots',
                        to: '/doctor-timeslot'
                    },
                    {
                        label: 'doctor.attendance',
                        icon: 'ri-suitcase-line ri-lg',
                        action: 'view',
                        resource: 'doctor-attendances',
                        to: '/doctor-attendance'
                    }
                ]
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'appointment.menu',
                icon: 'ri-todo-line ri-lg',
                action: 'view',
                resource: 'appointments',
                items: [
                    {
                        label: 'Make Appointment',
                        to: '/appointment/new',
                        icon: 'ri-draft-line ri-lg',
                        action: 'make_appointment',
                        resource: 'appointments'
                    },
                    {
                        label: 'appointment.current_appointment',
                        icon: 'ri-stack-line ri-lg', //remix icon
                        to: '/appointment/current',
                        action: 'view_current',
                        resource: 'appointments'
                    },
                    {
                        label: 'appointment.appointment_history',
                        icon: 'ri-file-history-line ri-lg', //remix icon
                        to: '/appointment/history',
                        action: 'view_history',
                        resource: 'appointments'
                    },
                    {
                        label: 'Refund Appointment',
                        icon: 'ri-refund-2-line ri-lg', //remix icon
                        to: '/appointment/refund',
                        action: 'view_refund',
                        resource: 'appointments'
                    },
                    {
                        label: 'Home Care',
                        icon: 'ri-home-heart-line ri-lg',
                        action: 'view',
                        resource: 'home-care',
                        to: '/home-care'
                    }
                ]
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'Ads',
                icon: 'ri-advertisement-line ri-lg',
                action: 'view',
                resource: 'ads',
                to: '/ads'
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'Coupons',
                icon: 'ri-coupon-line ri-lg',
                action: 'view',
                resource: 'coupons',
                items: [
                    {
                        label: 'Coupons Generate',
                        icon: 'ri-coupon-3-line ri-lg',
                        action: 'view',
                        resource: 'coupons',
                        to: '/coupon'
                    },
                    {
                        label: 'Gift Coupons',
                        icon: 'ri-coupon-3-line ri-lg',
                        action: 'view',
                        resource: 'coupons',
                        to: '/couponGift'
                    },
                    {
                        label: 'Coupons Redemption',
                        icon: 'ri-coupon-2-line ri-lg',
                        action: 'view',
                        resource: 'coupons',
                        to: '/coupon-redemption'
                    }
                ]
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'Chat Channel',
                icon: 'ri-wechat-line ri-lg',
                action: 'view',
                resource: 'chat_channel',
                items: [
                    {
                        label: 'Chat Room',
                        icon: 'ri-kakao-talk-line ri-lg',
                        to: '/chatroom',
                        action: 'view',
                        resource: 'chat_channel'
                    },
                    {
                        label: 'Chat Subscriptions',
                        icon: 'ri-chat-check-line ri-lg',
                        to: '/chat-subscriptions',
                        action: 'view',
                        resource: 'chat_channel'
                    }
                ]
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'Doctor/Medical Staff',
                icon: 'ri-stethoscope-line ri-lg',
                action: 'view',
                resource: 'doctors',
                to: '/doctor'
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'content.menu',
                icon: 'ri-book-line ri-lg',
                action: 'view',
                resource: 'content',
                to: '/content'
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'Notification',
                icon: 'ri-notification-line ri-lg',
                action: 'view',
                resource: 'custom-notification',
                to: '/notification'
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'Package',
                icon: 'ri-todo-line ri-lg',
                action: 'view',
                resource: 'corporate-package-sale',
                items: [
                    {
                        label: 'Package list',
                        icon: 'ri-archive-line ri-lg',
                        action: 'view',
                        resource: 'packages',
                        to: '/package'
                    },
                    {
                        label: 'Public sale',
                        icon: 'ri-team-line ri-lg',
                        to: '/package-sale/public',
                        action: 'view',
                        resource: 'public-package-sale'
                    },
                    {
                        label: 'Corporate sale',
                        icon: 'ri-organization-chart ri-lg',
                        to: '/package-sale/corporate',
                        action: 'view',
                        resource: 'corporate-package-sale'
                    },
                    {
                        label: 'Usage',
                        icon: 'ri-survey-line ri-lg',
                        action: 'view',
                        resource: 'package-usage',
                        to: '/package/usage'
                    },
                    {
                        label: 'Gift',
                        icon: 'ri-gift-2-line ri-lg',
                        action: 'view',
                        resource: 'transactions',
                        to: '/package-gifts'
                    }
                ]
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'Promotion Center',
                icon: 'ri-hand-coin-line ri-lg',
                action: 'view',
                resource: 'promotion-center',
                to: '/promotion-center'
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'patient.menu',
                icon: 'ri-team-line ri-lg',
                action: 'view',
                resource: 'patients',
                to: '/patient'
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'Pharmacies',
                icon: 'ri-medicine-bottle-line ri-lg',
                action: 'view',
                resource: 'pharmacies',
                to: '/pharmacy'
            }
        ]
    },

    {
        label: 'default',
        items: [
            {
                label: 'Set Up',
                icon: 'ri-organization-chart ri-lg',
                action: 'view',
                resource: 'settings',
                items: [
                    {
                        label: 'Corporate',
                        icon: 'ri-organization-chart ri-lg',
                        action: 'view',
                        resource: 'corporates',
                        to: '/corporate'
                    },
                    {
                        label: 'Services',
                        icon: 'ri-service-line ri-lg',
                        action: 'view',
                        resource: 'services',
                        to: '/service'
                    },
                    {
                        label: 'facility.menu',
                        icon: 'ri-hospital-line ri-lg',
                        action: 'view',
                        resource: 'facilities',
                        to: '/facility'
                    },
                    {
                        label: 'symptom.menu',
                        icon: 'ri-eye-line ri-lg',
                        action: 'view',
                        resource: 'symptoms',
                        to: '/symptom'
                    },
                    {
                        label: 'specialities.menu',
                        icon: 'ri-book-2-line ri-lg',
                        action: 'view',
                        resource: 'specialities',
                        to: '/speciality'
                    },
                    {
                        label: 'category.menu',
                        icon: 'ri-function-line ri-lg',
                        action: 'view',
                        resource: 'categories',
                        to: '/category'
                    },
                    {
                        label: 'Township',
                        icon: 'ri-truck-line ri-lg',
                        action: 'view',
                        resource: 'township-setup',
                        to: '/township-setup'
                    },
                    {
                        label: 'Hospital Service',
                        icon: 'ri-hospital-line ri-lg',
                        action: 'view',
                        resource: 'users',
                        to: '/hospital-service'
                    },
                    {
                        label: 'Home Care Township',
                        icon: 'ri-map-pin-line ri-lg',
                        action: 'view',
                        resource: 'home-care-townships',
                        to: '/home-care-townships'
                    }
                ]
            }
        ]
    },
    {
        label: 'default',
        items: [
            {
                label: 'chat.menu',
                icon: 'ri-customer-service-2-line ri-lg',
                action: 'view',
                resource: 'doctor-chat',
                to: '/doctor-chat'
            }
        ]
    },
    {
        label: 'default',
        items: [
            {
                label: 'setting.menu',
                icon: 'ri-settings-2-line ri-lg',
                action: 'view',
                resource: 'settings',
                // to: '/setting'
                items: [
                    {
                        // label: 'Mobile Setting',
                        label: 'Portal',
                        icon: 'ri-settings-2-line ri-lg',
                        action: 'view',
                        resource: 'settings',
                        to: '/setting'
                    },
                    {
                        label: 'Mobile',
                        icon: 'ri-settings-2-line ri-lg',
                        action: 'view',
                        resource: 'settings',
                        to: '/mobile-setting'
                    },
                    {
                        label: 'Force Update',
                        icon: 'ri-smartphone-line ri-lg',
                        action: 'view',
                        resource: 'force_update',
                        to: '/force-update'
                    },
                    {
                        label: 'role.menu',
                        icon: 'ri-shield-user-line ri-lg',
                        action: 'manage',
                        resource: 'roles-and-permissions',
                        to: '/roles-and-permission'
                    }
                ]
            }
        ]
    }
]

export default menuList
